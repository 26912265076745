.gifs {
  position: relative;
  width: 100px;
  height: 100px;
  &__text {
    position: absolute;
    color: $white;
    font-size: $font-size-base/2;
    font-family: $font-family-tertiary;
    animation-name: spin-around;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  @keyframes spin-around {
    to {
      transform: rotate(360deg);
    }
  }
  &__img {
    position: absolute;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    object-fit: cover;
    transition: $transition-easeOutback;
    &:hover {
      width: 100%;
      height: 100%;
    }
  }
}
