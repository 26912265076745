.typewriter {
  margin-top: 5rem;
  position: relative;
  transform: skewX(-7deg) rotate(-7deg);
  &__fieldset {
    min-height: 160px;
    border: 2px solid $white;
  }
  &__legend {
    position: absolute;
    left: 20%;
    top: -0.5em;
    width: 30%;
    background-color: $primary;
    transform: translate(-50%, 0);
    @include media-breakpoint-down(lg) {
      left: 25%;
      width: 35%;
    }
  }
  &__text {
    display: inline-block;
    color: $white;
    font-size: $h6-font-size;
    // background-color: $white;
    & > span {
      color: $white;
      border-right: 0.5em solid;
      animation: caret 1s steps(1) infinite;
    }
    @keyframes caret {
      50% {
        border-color: transparent;
      }
    }
  }
}
