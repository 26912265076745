.page-header {
  .logo {
    position: relative;
    svg {
      width: 100px;
      height: 30px;
      fill: $white;
      &:hover {
        animation: each-colors;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: forwards;
      }
      @keyframes each-colors {
        0% {
          fill: $secondary;
        }
        50% {
          fill: $tertiary;
        }
        100% {
          fill: $primary-light;
        }
      }
    }
  }
  &__nav {
    font-size: 0;
    li {
      position: relative;
      display: inline-block;
      min-width: 120px;
      border: 2px solid $white;
      border-right: none;
      &:last-child {
        border-right: 2px solid $white;
      }
      a {
        width: 100%;
        display: inline-block;
        text-decoration: none;
        padding: 0;
        font-size: $h5-font-size;
        font-family: $font-family-secondary;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $white;
        transform: rotate(0) scale(1);
        transition: $transition-base;
        -webkit-text-stroke: 1px transparent;
                text-stroke: 1px transparent;
        &:hover {
          color: transparent;
          -webkit-text-stroke: 1px $tertiary;
                  text-stroke: 1px $tertiary;
          transform: rotate(-30deg) scale(2);
          & + img {
            height: 100%;
          }
        }
        & + img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: 0;
          transition: $transition-base;
          pointer-events: none;
        }
      }
    }
    @include media-breakpoint-down(md) {
      position: fixed;
      height: 100%;
      width: 0%;
      top: 0;
      right: 0;
      background-color: rgba($primary, 0.9);
      overflow-x: hidden;
      transition: $transition-easeOutCubic;
      z-index: 10;
      .top-bar {
        border: 2px solid $white;
        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .page-header__list {
        position: relative;
        width: 80%;
        li {
          display: block;
          margin: 1rem 0;
          border-right: 2px solid $white;
          a {
            font-size: 3rem;
            &:hover {
              transform: none;
            }
          }
        }
      }
    }
  }
  &__lang {
     position: relative;
    a {
      font-size: $h5-font-size;
      font-family: $font-family-secondary;
      text-decoration: none;
      letter-spacing: 0.05em;
      color: $white;
      & + .lang-dropdown {
        position: absolute;
        border-radius: 3px;
        padding: 0 $btn-padding-x;
        margin-left: -$btn-padding-x;
        display: block;
        background-color: $white;
        white-space: nowrap;
        transform: scale(0);
        transition: $transition-base;
        &:before {
          content: '';
          position: absolute;
          top: -2%;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 10px;
          height: 10px;
          background-color: $white;
          transform: rotate(45deg);
          z-index: -1;
        }
        a {
          color: $primary;
        }
        &:hover {
          transform: scale(1);
        }
      }
    }
    &:hover .lang-dropdown {
      transform: scale(1);
    }
  }
  &__button {
    position: relative;
    margin: 0 15px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    span {
      position: absolute;
      width: 100%;
      height: 4px;
      left: 0;
      border-radius: 4px;
      background-color: $white;
      &:before, &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        height: 4px;
        border-radius: 4px;
        background-color: $white;
      }
      &:before {
        top: -10px;
      }
      &:after {
        bottom: -10px;
      }
    }
    &:focus {
      outline: none;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 1rem;
  }
}
