@font-face {
  font-weight: 300;
  font-family: 'Circe';
  src: local('CirceLight'), url('../../fonts/circe/CirceLight.otf') format('opentype');
}
@font-face {
  font-weight: 400;
  font-family: 'Circe';
  src: local('CirceRegular'), url('../../fonts/circe/CirceRegular.otf') format('opentype');
}

@font-face {
  font-weight: normal;
  font-family: 'Comic Sans';
  src: local('Comic Sans MS'), url('../../fonts/comicsans/Comic sans.ttf') format('truetype');
}
@font-face {
  font-weight: 700;
  font-family: 'Cinematografica';
  src: local('Cinematografica-Extrabold'), url('../../fonts/cinematografica/Cinematografica-Extrabold.ttf') format('truetype');
}