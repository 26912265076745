.creeping-line {
  max-width: 300px;
  margin: 0 auto;
  border: 2px solid $white;
  transform: rotate(-8deg);
  &__group {
    border-right: 2px solid $white;
    svg {
      width: 10px;
      height: 10px;
      margin: 0 0.25rem;
      cursor: pointer;
    }
  }
  &__text {
    color: $white;
    font-family: $font-family-sans-serif-tertiary;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    letter-spacing: 0.03em;
    overflow: hidden;
  }
}
