.outline {
  position: relative;
  color: transparent;
  transition: $transition-base;
  -webkit-text-stroke: 1px $white;
  text-stroke: 1px $white;
  &__link {
    &:hover, &.active {
      text-decoration: none;
      color: $white;
      -webkit-text-stroke: 1px transparent;
      text-stroke: 1px transparent;
    }
  }
  &_invert {
    color: $white;
    -webkit-text-stroke: 1px $primary;
    text-stroke: 1px $primary;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5%;
    width: 100%;
    height: 6px;
    background: url('../../img/underline.png') center repeat-x;
  }
  &_uline_false {
    &:after {
      background: none;
    }
  }
}
