.socials {
  position: relative;
  margin: 4rem 0;
  &__img {
    max-width: 200px;
    height: 70px;
    filter: contrast(100%) sepia(0) hue-rotate(0deg) brightness(1) saturate(1);
    transition: $transition-base;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      height: auto;
    }
  }
  &:hover {
    img {
      filter: contrast(40%) sepia(100%) hue-rotate(220deg) brightness(0.8) saturate(500%);
      & ~ a .svg-inline--fa {
        transform: scale(1);
        transition: $transition-easeOutback;
      }
    }
  }
  .svg-inline--fa {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    box-shadow: $box-shadow-lg;
    transform: scale(0);
    &:hover {
      transform: scale(1);
      transition: $transition-easeOutback;
    }
    &.fa-facebook-f {
      right: 20%;
      background-image: -moz-linear-gradient( 90deg, rgb(72,120,218) 0%, rgb(115,174,255) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(72,120,218) 0%, rgb(115,174,255) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(72,120,218) 0%, rgb(115,174,255) 100%);
    }
    &.fa-instagram {
      left: 20%;
      background-image: -moz-linear-gradient( 90deg, rgb(249,151,57) 0%, rgb(214,48,134) 54%, rgb(181,129,195) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(249,151,57) 0%, rgb(214,48,134) 54%, rgb(181,129,195) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(249,151,57) 0%, rgb(214,48,134) 54%, rgb(181,129,195) 100%);
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 2.5rem 0;
  }
}
