.wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    min-width: 15px;
    height: 100%;
    background: url('../../img/page-border.png') top repeat-y;
    background-size: cover;
    z-index: 1;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
    transform: scale(-1, 1);
  }
}
canvas {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
}
