.page-section {
  position: relative;
  &__box, &__group, &__inner {
    width: 100%;
    position: relative;
  }
  &__title {
    letter-spacing: 0.06em;
  }
  &__desc {
    color: $white;
    font-family: $font-family-sans-serif-tertiary;
    font-weight: $font-weight-base;
    font-size: 0.875rem;
    line-height: $line-height-sm;
    letter-spacing: -0.02em;
  }
  &__caption {
    color: $primary;
    font-size: 1.625rem;
    font-family: $font-family-sans-serif-tertiary;
    font-weight: $font-weight-bold;
    letter-spacing: -0.02em;
    line-height: $line-height-sm;
    @include media-breakpoint-down(sm) {
      font-size: 1.125rem;
    }
  }
  &__text {
    color: $white;
    font-family: $font-family-sans-serif-base;
    font-weight: $font-weight-light;
    font-size: 1.125em;
    line-height: $line-height-base;
    letter-spacing: -0.02em;
    @include media-breakpoint-down(sm) {
      font-size: 0.875rem;
    }
  }
  &__img {
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  // top-hero
  &.top-hero {
    height: 70vmin;
    min-height: 400px;
    .message {
      svg:first-child {
        top: -2%;
        height: 20px;
      }
      top: 5%;
      left: -20%;
      transform: rotate(-30deg);
    }
    .page-section__box {
      height: 100%;
      min-height: 370px;
      transform: rotate(3deg);
      overflow: hidden;
      svg {
        position: absolute;
        bottom: -4px;
        height: 8px;
        width: 100%;
      }
      .mark-zuc {
        height: 100%;
      }
    }
    .page-section__text {
      img {
        height: 20px;
      }
    }
    .page-section__desc {
      transform: rotate(-8deg);
      img {
        height: 20px;
      }
    }
    .page-section__img {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      &:nth-child(1) {
        max-width: 290px;
        z-index: 1;
      }
      &:nth-child(2) {
        margin-top: 35%;
        max-width: 250px;
      }
      &:nth-child(3) {
        margin-top: 5%;
        max-width: 300px;
      }
      &:nth-child(4) {
        max-width: 400px;
        margin-top: 50%;
        z-index: 1;
      }
    }
  }
  // top-hero-end
  // numbers
  &.numbers {
    padding: 4rem 0;
    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
    }
    .page-section__caption span {
      display: inline-block;
      background-color: $white;
    }
    .page-section__inner {
      svg {
        position: absolute;
        top: 0;
        width: 50%;
        left: 80%;
      }
      &:nth-child(1) {
        transform: rotate(-8deg);
        span {
          clip-path: polygon(0 0, 100% 0, 85% 100%, 5% 100%);
        }
        svg {
          left: 90%;
        }
      }
      &:nth-child(2) {
        transform: rotate(6deg);
        span {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
        }
      }
      &:nth-child(3) {
        transform: rotate(-2deg);
        span {
          clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%);
        }
      }
      &:nth-child(4) {
        transform: rotate(8deg);
        span {
          clip-path: polygon(0 0%, 100% 0, 100% 100%, 5% 100%);
        }
      }
    }
  }
  // Numbers end
  // Partners
  &.partner {
    .container {
      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
    margin: 10em 0;
    padding: 4em 0;
    @include media-breakpoint-down(md) {
      margin: 4em 0;
    }
    @include media-breakpoint-down(sm) {
      margin: 2em 0;
    }
    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -25%;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: url('../../img/hulk.png') top no-repeat;
      background-size: contain;
    }
    &:after {
      top: 75%;
      background: url('../../img/hulk-legs.png') top no-repeat;
    }
    .page-section__group {
      img:last-child {
        transform: scale(-1, 1);
      }
    }
    .page-section__img {
      position: absolute;
      bottom: -20%;
      transform-origin: top;
      transition: $transition-base;
      cursor: help;
      z-index: -1;
      &:hover {
        bottom: -25%;
        animation-name: wiggle-wiggle;
        animation-duration: 1s;
      }
      @keyframes wiggle-wiggle {
        0% {
          transform: rotate(0);
        }
        25% {
          transform: rotate(-10deg);
        }
        50% {
          transform: rotate(8deg);
        }
        75% {
          transform: rotate(-5deg);
        }
        100% {
          transform: rotate(0);
        }
      }
    }
    .page-section__box {
      transform: skewX(-7deg) rotate(-7deg);
      background-color: $white;
      background-image: url('../../img/splashes.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 1;
    }
    .page-section__title {
      vertical-align: middle;
      &:after {
        background: url('../../img/rule.png') center repeat-x;
      }
    }
    .page-section__desc {
      position: absolute;
      bottom: -30%;
      right: 110px;
      width: 287px;
      transform: rotate(10deg);
      svg {
        max-width: 100%;
        position: absolute;
        left: -50%;
        top: 40px;
        transform: rotate(-10deg);
      }
      @include media-breakpoint-down(lg) {
        right: 20px;
      }
    }
  }
  // Partners end
  // Services
  &.services {
    min-height: 800px;
    padding: 4rem 0;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
    }
    .page-section__inner {
      max-width: 425px;
      @include media-breakpoint-down(md) {
        top: auto!important;
        max-width: 750px;
        padding: 0 2rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
      h6 {
        cursor: move;
        .gifs {
          position: absolute;
          opacity: 0;
          transition: $transition-fade;
          &__text {
            color: lighten($tertiary, 25%);
          }
        }
      }
      &:nth-child(1) {
        top: 15vh;
        h6 > img {
          top: -50px;
          left: 0;
          right: 0;
        }
        .gifs {
          right: -10%;
        }
      }
      &:nth-child(2) {
        top: 25vh;
        margin: 0 -5%;
        h6 {
          & > img {
           top: -80px;
           left: 0;
           right: 0;
         }
         span {
           transform: scale(-1, 1);
         }
        }
        .gifs {
          left: 10%;
        }
      }
      &:nth-child(3) {
        h6 > img {
          top: -40px;
          right: -40px;
        }
        .gifs {
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }
    }
    .page-section__box {
      background-color: $primary;
      border: 2px solid $white;
      transition: $transition-base;
      h6 {
        line-height: normal;
        background-color: $primary;
        border-bottom: 2px solid $white;
        svg {
          width: 10px;
          height: 10px;
        }
        img {
          position: absolute;
          margin: 0 auto;
        }
      }
      &:hover {
        background-color: $white;
        .outline {
          color: $primary;
          &:after {
            background: url('../../img/rule.png') 50% repeat-x;
          }
        }
        .page-section__text {
          color: $primary;
        }
        .gifs {
          opacity: 1;
        }
      }
    }
    .page-section__group {
      padding: 3.75rem 2rem;
    }
    .page-section__text {
      font-size: $font-size-base * 0.875;
    }
  }
  // Srvices-end
  // Clients
  &.clients {
    padding: 4rem 0;
    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
    }
    .clients__title {
      .outline {
        color: $primary;
        &:after {
          filter: contrast(30%) sepia(100%) hue-rotate(180deg) brightness(0.8) saturate(500%);
        }
      }
    }
    .gifs {
      position: absolute;
      top: -6rem;
      left: 0;
      right: 0;
      &__text {
        letter-spacing: 0.3rem;
        color: $tertiary;
        font-size: $font-size-base * 0.875;
      }
      &__img {
        width: 60%;
        height: auto;
        border-radius: 0;
      }
    }
    .message {
      max-width: 240px;
      transform: rotate(20deg);
      top: -6rem;
      right: 0;
      h5 {
        max-width: 250px;
        position: relative;
      }
      svg {
        left: -15%;
        transform: scale(-1, 1);
      }
      .page-section__desc {
        position: relative;
        top: 3em;
        color: $secondary;
      }
    }
    .page-section__wrap {
      padding-bottom: 3rem;
      background-color: $white;
    }
  }
  // Contacts
  &.contacts {
    padding: 4rem 0;
    @include media-breakpoint-down(sm) {
      padding: 2rem 0;
    }
    .h1 {
      overflow: hidden;
    }
    .h3 {
      letter-spacing: 0.03em;
      svg {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 0;
        right: -10px;
        color: $white;
      }
    }
    .page-section__img {
      position: relative;
      max-height: 90px;
      bottom: 5.5rem;
      @include media-breakpoint-down(sm) {
        bottom: 3.5rem;
        max-height: 60px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 1rem;
  }
}
