.page-footer {
  position: relative;
  padding: 2rem 0;
  .h5 {
    text-decoration: none;
    transition: $transition-fade;
    &.to-top {
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }
    transform: rotate(90deg);
    transform-origin: right;
    svg {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      opacity: 0.3;
    }
    @include media-breakpoint-down(lg) {
      transform: rotate(0deg);
      span {
        display: inline-block;
        transform: rotate(90deg);
      }
    }
  }
}
