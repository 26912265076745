.message {
  position: absolute;
  padding: 2em 1em;
  max-width: 200px;
  z-index: 1;
  h5 {
    text-decoration: underline;
  }
  svg {
    position: absolute;
    top: 5%;
    left: -30%;
    height: 100%;
  }
}
