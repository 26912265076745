.loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(1);
  transition: $transition-easeOutCubic;
  z-index: $zindex-fixed;
  &.loaded {
    transform: scale(0);
  }
  .logo {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 300px;
    fill: $white;
    opacity: 0.5;
  }
  &__img {
    display: block;
    width: 100%;
    max-width: 350px;
    @include media-breakpoint-down(lg) {
      max-width: 250px;
    }
  }
  &-jdun {
    position: relative;
    animation-name: jdun;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    z-index: 1;
    .resize {
      position: absolute;
      top:0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      .loader__img {
        filter: contrast(50%) sepia(100%) hue-rotate(170deg) brightness(1) saturate(300%)
      }
    }
    @keyframes jdun {
      0% {
        transform: rotate(5deg);
      }
      33% {
        transform: rotate(-10deg);
      }
      66% {
        transform: rotate(10deg);
      }
      100% {
        transform: rotate(-5deg);
      }
    }
  }
}
